<template>
  <v-container fluid>
    <div class="pb-2">
      <v-btn class="ma-1" @click.stop="getEstudios" color="primary" small>
        <v-icon small left>mdi-refresh</v-icon>
        Recargar
      </v-btn>
      <v-btn class="ma-1" to="/control-pagos" color="primary" small>
        <v-icon small left>mdi-account-credit-card</v-icon>
        Control pagos
      </v-btn>
      <v-btn class="ma-1" to="/tramites-instalaciones" color="primary" small>
        <v-icon small left>mdi-gavel</v-icon>
        Tramites instalaciones
      </v-btn>
    </div>
    <div class="d-flex pb-2" style="gap: 10px">
      <v-text-field style="max-width: 500px" placeholder="Búsqueda..." v-model="search_" outlined filled hide-details
        append-icon="mdi-magnify">
      </v-text-field>
      <v-select style="max-width: 200px" outlined filled hide-details v-model="filterKey" :items="filterKeys">
      </v-select>

      <div class="d-flex flex-column ">
        <v-checkbox v-model="filterTypes" label="Instalaciones" value="instalacion" dense hide-details
          class="mt-0"></v-checkbox>
        <v-checkbox v-model="filterTypes" label="Cargadores" value="cargador" dense hide-details
          class="mt-0"></v-checkbox>
      </div>

    </div>
    <div class="d-flex" style="overflow-x: auto; gap: 20px">
      <div class="d-flex flex-column" :key="column.title" v-for="column in columns">
        <v-card class="rounded-lg d-flex flex-column" min-width="300" width="100%">
          <v-toolbar dark class="flex-grow-0" dense :color="column.color">
            <v-icon left>{{ column.icon }}</v-icon>
            <v-toolbar-title>{{ column.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <span>{{ column.estudios.length }}</span>
          </v-toolbar>

          <draggable
            v-model="column.estudios"
            :animation="200"
            ghost-class="ghost-card"
            group="tasks"
            class="pa-4 flex-grow-1"
            style="height: auto"
            @change="(ev) => change(ev, column)"
            :scroll-sensitivity="9000"
            :force-fallback="true"
          >
            <component
              :is="getTarjeta(task)"
              v-for="task, index in applyFilter(column.estudios)"
              :key="index"
              :estudio="task"
              @edit="([id, tipo ]) => {
                showResumen.idEstudio = id;
                showResumen.tipo = tipo;
              }"
            >
            </component>
          </draggable>

        </v-card>
        <div class="pa-2 d-flex flex-column">
          <div class="d-flex">
            Total compra:
            <v-spacer></v-spacer>
            {{ money.format(totales[column.idEstado].preciosCompra) }}
          </div>
          <div class="d-flex">
            Total venta:
            <v-spacer></v-spacer>
            {{ money.format(totales[column.idEstado].preciosVenta) }}
          </div>
          <v-btn v-if="noload.has(column.idEstado)" @click.stop="load(column.idEstado)" text>
            <v-icon left>mdi-plus</v-icon>
            Cargar</v-btn>
        </div>
      </div>
    </div>

    <v-dialog transition="dialog-bottom-transition" :value="showResumen.idEstudio" max-width="1200"
      @input="(v) => (v == false ? (showResumen.idEstudio = null) : null)">
      <component :is="showResumen.tipo" :key="showResumen.idEstudio" :id-estudio="showResumen.idEstudio" @close="() => {
        showResumen.idEstudio = null
        showResumen.tipo = null
      }" @reload="getEstudios" />
    </v-dialog>
  </v-container>
</template>

<script>
import { debounce } from "@/utils/index.js";
import draggable from "vuedraggable";
import TarjetaInstalacion from "../components/kanban/TarjetaInstalacion.vue";
import TarjetaCargador from "../components/kanban/TarjetaCargador.vue";
import GestionTarjeta from "../components/gestion/GestionTarjeta.vue";

import ResumenInstalacion from "../components/kanban/ResumenInstalacion.vue";
import ResumenCargador from "../components/kanban/ResumenCargador.vue";

export default {
  components: {
    draggable,
    TarjetaInstalacion,
    TarjetaCargador,
    GestionTarjeta,
    ResumenInstalacion,
    ResumenCargador,
  },
  data() {
    return {
      search_: "",
      search: "",
      columns: [],
      showResumen: {
        idEstudio: null,
        tipo: null
      },
      money: Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }),
      filterKey: "nombreProyecto",
      filterKeys: [
        { text: "Nombre estudio", value: "nombreProyecto" },
        { text: "Nº Estudio", value: "idEstudio" },
        { text: "Instaladora", value: "instaladora.nombreInstaladora" },
        { text: "Comercial", value: "comercial.nombre" },
        { text: "Responsable", value: "comercial.responsable.nombre" },
      ],
      filterTypes: ['cargador', 'instalacion'],
      noload: new Set([1038]),
    };
  },
  mounted() {
    this.getEstudios();
  },
  methods: {
    async getEstudios() {
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/instalaciones`,
        params: {
          no_load: [...this.noload].join(","),
        },
      });

      this.columns = data;
    },
    async change(ev, estado) {
      if (!ev.added) return;

      const { element } = ev.added;

      let tipo = '';
      if (element.instalacion) tipo = 'instalacion';
      if (element.cargador) tipo = 'cargador';

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/estudios/cambiar_estado`,
        data: {
          idEstudio: element.idEstudio,
          idEstado: estado.idEstado,
          tipo
        },
      });
    },
    resolve(path, obj, separator = ".") {
      let properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce((prev, curr) => prev?.[curr], obj);
    },
    
    applyFilter(estudios) {
      return estudios.filter((e) => {
        if (!this.filterTypes.some(type => !!e[type])) return false;
        let val = this.resolve(this.filterKey, e);
        if (val == null) return false;
        return String(val).toLowerCase().includes(this.search.toLowerCase());
      });
    },
    load(id) {
      this.noload.delete(id);
      this.getEstudios();
    },
    getTarjeta(e) {
      if (e.instalacion) return 'TarjetaInstalacion'
      if (e.cargador) return 'TarjetaCargador'
    }
  },
  computed: {
    estados() {
      return this.columns.map((e) => ({ ...e, estudios: null }));
    },
    totales() {
      if (!this.columns.length) return {};
      return this.columns.reduce((result, { idEstado, estudios }) => {
        result[idEstado] = {
          preciosVenta: estudios.reduce(
            (res, e) => (e.instalacion?.precioVenta || 0) + (e.cargador?.precioVenta || 0) + res,
            0
          ),
          preciosCompra: estudios.reduce(
            (res, e) => (e.instalacion?.precioCompra || 0) + (e.cargador?.precioCompra || 0) + res,
            0
          ),
        };
        return result;
      }, {});
    },
  },
  watch: {
    search_: debounce(function (newVal) {
      this.search = newVal;
    }, 500),
  },
};
</script>

<style></style>
