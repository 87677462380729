<template>
  <v-card @click.stop="$emit('edit', [estudio.idEstudio, 'ResumenCargador'] )" outlined class="mb-2 pa-2 text-sm-body-2"
    style="border-left: 2px solid var(--v-secondary-base)"
  >
    <div class="d-flex items-center">
      <h4>{{ estudio.nombreProyecto }} - {{ estudio.idEstudio }}</h4>
      <v-spacer></v-spacer>

      <v-icon small v-if="estudio?.cargador?.tipoCliente" :color="estudio?.cargador?.tipoCliente?.color" >
        {{ estudio?.cargador?.tipoCliente?.icon }}
      </v-icon>

      <v-icon small>
        mdi-ev-station
      </v-icon>
    </div>

    <div class="d-flex flex-column text--disabled">
      <div class="d-flex" style="gap: 4px" >
        <v-avatar v-for="pago in estudio?.cargador?.pagos" :key="pago.idPago" :color=" pago.pagado ? 'success' : 'error' " size="8" ></v-avatar>
        <v-spacer></v-spacer>
        <v-tooltip bottom v-if="estudio?.cargador?.financiado" >
          <template v-slot:activator="{ on, attrs }" >
            <v-icon small color="success" v-bind="attrs" v-on="on" >mdi-handshake</v-icon>
          </template>
          Financiado
        </v-tooltip>
      </div>
      <span class="d-flex">
       Direccion: <v-spacer /> {{ estudio?.cups_principal?.direccion }}
      </span>
      <span class="d-flex">
        Comercial: <v-spacer /> {{ estudio?.comercial?.nombre }}
      </span>
      <span class="d-flex" v-if="estudio?.instaladora?.nombreInstaladora">
        Instalador: <v-spacer /> {{ estudio?.instaladora?.nombreInstaladora }}
      </span>
      <span class="d-flex" v-if="estudio?.cargador?.fechaFirmaObra" >
        Fecha fin obra: <v-spacer /> {{ parseDate(estudio?.cargador?.fechaFirmaObra) }}
      </span>
      <span class="d-flex" v-if="estudio?.cargador?.precioCompra" >
        Precio compra: <v-spacer /> {{ money.format(estudio?.cargador?.precioCompra) }}
      </span>
      <span class="d-flex" v-if="estudio?.cargador?.precioVenta" >
        Precio venta: <v-spacer /> {{ money.format(estudio?.cargador?.precioVenta) }}
      </span>
    </div>
  </v-card>
</template>

<script>
import { parseDate } from '@/utils/index.js'

export default {
  props: {
    estudio: Object,
  },
  data() {
    return {
      money: Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }),
    }
  },
  methods: {
    parseDate
  }
};
</script>

<style></style>
